import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BIDS,
  GET_BID_DETAILS,
  CREATE_BID,
  UPDATE_BID,
  DELETE_BID,
} from "./actionTypes"
import {
  getBidsFail,
  getBidsSuccess,
  getBidDetailsSuccess,
  getBidDetailsFail,
  createBidFail,
  createBidSuccess,
  updateBidSuccess,
  updateBidFail,
  deleteBidSuccess,
  deleteBidFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"
import { showToasterAction } from "store/actions"

// Bid - This line cannot be edited or removed
function getBidsAPi({ productId }) {
  if (productId) {
    return get(`/bid/user/product/${productId ? productId : ""}`)
  } else {
    return get(`/bid/user/all`)
  }
}

const getBidDetailsAPi = bidId => {
  return get(`/bid/user/single/${bidId}`)
}

const createBidApi = ({ bid }) => {
  return post("/bid/user/new", bid)
}

const updateBidApi = ({ bid, bidId }) => {
  return ApiPut(`/bid/user/${bidId}`, bid)
}

const deleteBidApi = bidId => {
  return del(`/bid/user/${bidId}`)
}

function* fetchBids({ payload }) {
  try {
    const response = yield call(getBidsAPi, payload)
    yield put(getBidsSuccess(response))
  } catch (error) {
    yield put(getBidsFail(error))
  }
}

function* fetchBidDetails({ payload: bidId }) {
  try {
    const response = yield call(getBidDetailsAPi, bidId)
    yield put(getBidDetailsSuccess(response))
  } catch (error) {
    yield put(getBidDetailsFail(error))
  }
}

function* onCreateBid({ payload }) {
  try {
    const response = yield call(createBidApi, payload)
    if (response?.message) {
      payload.dispatch(
        showToasterAction({
          data: {
            title: "",
            message: response?.message,
          },
          type: "default",
        })
      )
    } else if (response) {
      yield put(createBidSuccess(response))
      // payload?.history?.push("/bids")
      // doneNotification("Bid Created Successfully!")
      payload.dispatch(
        showToasterAction({
          data: {
            title: "",
            message: "Sent Successfully!",
          },
          type: "success",
        })
      )
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createBidFail(error))
  }
}

function* onUpdateBid({ payload }) {
  try {
    const response = yield call(updateBidApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Bid Updated Successfully!",
        title: "",
      })
      yield put(updateBidSuccess(response))
      if (payload.history) {
        payload.history.push("/bids")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateBidFail(error.response.data))
  }
}

function* onDeleteBid({ BidId, history }) {
  try {
    const response = yield call(deleteBidApi, BidId)
    yield put(deleteBidSuccess(response))
    Notification({
      type: "success",
      message: "Bid Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteBidFail(error?.response?.data))
  }
}

function* bidSaga() {
  yield takeEvery(GET_BIDS, fetchBids)
  yield takeEvery(GET_BID_DETAILS, fetchBidDetails)
  yield takeEvery(CREATE_BID, onCreateBid)
  yield takeEvery(UPDATE_BID, onUpdateBid)
  yield takeEvery(DELETE_BID, onDeleteBid)
}

export default bidSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
