import {
  GET_USERADDRESSS,
  GET_USERADDRESSS_SUCCESS,
  GET_USERADDRESSS_FAIL,
  GET_USERADDRESS_DETAILS,
  GET_USERADDRESS_DETAILS_SUCCESS,
  GET_USERADDRESS_DETAILS_FAIL,
  CREATE_USERADDRESS,
  CREATE_USERADDRESS_SUCCESS,
  CREATE_USERADDRESS_FAIL,
  UPDATE_USERADDRESS,
  UPDATE_USERADDRESS_SUCCESS,
  UPDATE_USERADDRESS_FAIL,
  DELETE_USERADDRESS_SUCCESS,
  DELETE_USERADDRESS_FAIL,
  DELETE_USERADDRESS,
} from "./actionTypes"

// UserAddress - This line cannot be edited or removed
export const getUserAddresss = (page, sort, limit, searchText) => ({
  type: GET_USERADDRESSS,
  payload: { page, sort, limit, searchText },
})

export const getUserAddresssSuccess = useraddresss => ({
  type: GET_USERADDRESSS_SUCCESS,
  payload: useraddresss,
})

export const getUserAddresssFail = error => ({
  type: GET_USERADDRESSS_FAIL,
  payload: error,
})

export const getUserAddressDetails = useraddressId => ({
  type: GET_USERADDRESS_DETAILS,
  payload: useraddressId,
})

export const getUserAddressDetailsSuccess = useraddressDetails => ({
  type: GET_USERADDRESS_DETAILS_SUCCESS,
  payload: useraddressDetails,
})

export const getUserAddressDetailsFail = error => ({
  type: GET_USERADDRESS_DETAILS_FAIL,
  payload: error,
})

export const createUserAddress = (useraddress, history) => ({
  type: CREATE_USERADDRESS,
  payload: { useraddress, history },
})

export const createUserAddressSuccess = useraddress => ({
  type: CREATE_USERADDRESS_SUCCESS,
  payload: useraddress,
})

export const createUserAddressFail = error => ({
  type: CREATE_USERADDRESS_FAIL,
  payload: error,
})

export const getUserAddressDetail = useraddressId => ({
  type: GET_USERADDRESS_DETAILS,
  payload: useraddressId,
})

export const updateUserAddress = (useraddress, useraddressId, history) => ({
  type: UPDATE_USERADDRESS,
  payload: { useraddress, useraddressId, history },
})

export const updateUserAddressSuccess = useraddress => ({
  type: UPDATE_USERADDRESS_SUCCESS,
  payload: useraddress,
})

export const updateUserAddressFail = error => ({
  type: UPDATE_USERADDRESS_FAIL,
  payload: error,
})

export const deleteUserAddress = (useraddressId, history) => ({
  type: DELETE_USERADDRESS,
  payload: { useraddressId, history },
})

export const deleteUserAddressSuccess = useraddress => ({
  type: DELETE_USERADDRESS_SUCCESS,
  payload: useraddress,
})

export const deleteUserAddressFail = error => ({
  type: DELETE_USERADDRESS_FAIL,
  payload: error,
})
