import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_PREORDERS,
  GET_PREORDER_DETAILS,
  CREATE_PREORDER,
  UPDATE_PREORDER,
  DELETE_PREORDER,
} from "./actionTypes"
import {
  getPreordersFail,
  getPreordersSuccess,
  getPreorderDetailsSuccess,
  getPreorderDetailsFail,
  createPreorderFail,
  createPreorderSuccess,
  updatePreorderSuccess,
  updatePreorderFail,
  deletePreorderSuccess,
  deletePreorderFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"

// Preorder - This line cannot be edited or removed
function getPreordersAPi({ page, limit, searchText }) {
  return get(
    `/preorder/user/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}`
  )
}

const getPreorderDetailsAPi = PreorderId => {
  return get(`/preorder/user/${PreorderId}`)
}

const createPreorderApi = ({ preorder }) => {
  return post("/preorder/user/new ", preorder)
}

const updatePreorderApi = ({ preorder, PreorderId }) => {
  return ApiPut(`/preorder/admin/${PreorderId}`, preorder)
}

const deletePreorderApi = PreorderId => {
  return del(`/preorder/admin/${PreorderId}`)
}

function* fetchPreorders({ payload }) {
  try {
    const response = yield call(getPreordersAPi, payload)
    yield put(getPreordersSuccess(response))
  } catch (error) {
    yield put(getPreordersFail(error))
  }
}

function* fetchPreorderDetails({ payload: PreorderId }) {
  try {
    const response = yield call(getPreorderDetailsAPi, PreorderId)
    yield put(getPreorderDetailsSuccess(response))
  } catch (error) {
    yield put(getPreorderDetailsFail(error))
  }
}

function* onCreatePreorder({ payload }) {
  try {
    const response = yield call(createPreorderApi, payload)
    // if (response?.message) {
    //   Notification({
    //     type: "error",
    //     message: response?.message,
    //     title: "",
    //   })
    // } else if (response) {
    yield put(createPreorderSuccess(response))
    Notification({
      type: "success",
      message: "Preorder Created Successfully!",
      title: "",
    })
    // payload?.history?.push("/preorders")
    // doneNotification("Preorder Created Successfully!")
    // }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createPreorderFail(error))
  }
}

function* onUpdatePreorder({ payload }) {
  try {
    const response = yield call(updatePreorderApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Preorder Updated Successfully!",
        title: "",
      })
      yield put(updatePreorderSuccess(response))
      if (payload.history) {
        payload.history.push("/preorders")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updatePreorderFail(error.response.data))
  }
}

function* onDeletePreorder({ PreorderId, history }) {
  try {
    const response = yield call(deletePreorderApi, PreorderId)
    yield put(deletePreorderSuccess(response))
    Notification({
      type: "success",
      message: "Preorder Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deletePreorderFail(error?.response?.data))
  }
}

function* preorderSaga() {
  yield takeEvery(GET_PREORDERS, fetchPreorders)
  yield takeEvery(GET_PREORDER_DETAILS, fetchPreorderDetails)
  yield takeEvery(CREATE_PREORDER, onCreatePreorder)
  yield takeEvery(UPDATE_PREORDER, onUpdatePreorder)
  yield takeEvery(DELETE_PREORDER, onDeletePreorder)
}

export default preorderSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
