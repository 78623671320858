import {
  LOGIN_USER_MOBILE,
  LOGIN_USER_MOBILE_SUCCESS,
  LOGIN_USER_MOBILE_FAILD,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILD,
  LOGIN_USER_OTP,
  LOGIN_USER_OTP_SUCCESS,
  LOGIN_USER_OTP_FAILD,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILD,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILD,
  REGISTER_USER_MOBILE,
  REGISTER_USER_MOBILE_SUCCESS,
  REGISTER_USER_MOBILE_FAILD,
  REGISTER_USER_OTP,
  REGISTER_USER_OTP_SUCCESS,
  REGISTER_USER_OTP_FAILD,
} from "./actionTypes"

export const getUser = () => {
  return {
    type: GET_USER,
  }
}

export const getUserSuccess = user => {
  return {
    type: GET_USER_SUCCESS,
    payload: user,
  }
}

export const getUserError = error => {
  return {
    type: GET_USER_FAILD,
    payload: error,
  }
}

export const loginUserOtp = otp => {
  return {
    type: LOGIN_USER_OTP,
    payload: otp,
  }
}

export const loginUserOtpSuccess = user => {
  return {
    type: LOGIN_USER_OTP_SUCCESS,
    payload: user,
  }
}

export const loginUserOtpError = error => {
  return {
    type: LOGIN_USER_OTP_FAILD,
    payload: error,
  }
}

export const loginUserMobile = (mobile, type) => {
  return {
    type: LOGIN_USER_MOBILE,
    payload: { mobile, type },
  }
}

export const loginUserMobileSuccess = user => {
  return {
    type: LOGIN_USER_MOBILE_SUCCESS,
    payload: user,
  }
}

export const loginUserMobileError = error => {
  return {
    type: LOGIN_USER_MOBILE_FAILD,
    payload: error,
  }
}

export const registerUserOtp = registerOtp => {
  return {
    type: REGISTER_USER_OTP,
    payload: registerOtp,
  }
}

export const registerUserOtpSuccess = user => {
  return {
    type: REGISTER_USER_OTP_SUCCESS,
    payload: user,
  }
}

export const registerUserOtpError = error => {
  return {
    type: REGISTER_USER_OTP_FAILD,
    payload: error,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const logoutuserError = error => {
  return {
    type: LOGOUT_USER_FAILD,
    payload: error,
  }
}

export const updateUserInfo = input => {
  return {
    type: UPDATE_USER_INFO,
    payload: input,
  }
}

export const updateUserInfoSuccess = userInfo => {
  return {
    type: UPDATE_USER_INFO_SUCCESS,
    payload: userInfo,
  }
}

export const updateUserInfoError = error => {
  return {
    type: UPDATE_USER_INFO_FAILD,
    payload: error,
  }
}
