/* UserAddresss - This line cannot be edited or removed */
export const GET_USERADDRESSS = "GET_USERADDRESSS"
export const GET_USERADDRESSS_SUCCESS = "GET_USERADDRESSS_SUCCESS"
export const GET_USERADDRESSS_FAIL = "GET_USERADDRESSS_FAIL"

/* UserAddress_DETAILS */
export const GET_USERADDRESS_DETAILS = "GET_USERADDRESS_DETAILS"
export const GET_USERADDRESS_DETAILS_SUCCESS = "GET_USERADDRESS_DETAILS_SUCCESS"
export const GET_USERADDRESS_DETAILS_FAIL = "GET_USERADDRESS_DETAILS_FAIL"

/* CREATE UserAddress */
export const CREATE_USERADDRESS = "CREATE_USERADDRESS"
export const CREATE_USERADDRESS_SUCCESS = "CREATE_USERADDRESS_SUCCESS"
export const CREATE_USERADDRESS_FAIL = "CREATE_USERADDRESS_FAIL"

/* Edit UserAddress */
export const UPDATE_USERADDRESS = "UPDATE_USERADDRESS"
export const UPDATE_USERADDRESS_SUCCESS = "UPDATE_USERADDRESS_SUCCESS"
export const UPDATE_USERADDRESS_FAIL = "UPDATE_USERADDRESS_FAIL"

/* Delete UserAddress */
export const DELETE_USERADDRESS = "DELETE_USERADDRESS"
export const DELETE_USERADDRESS_SUCCESS = "DELETE_USERADDRESS_SUCCESS"
export const DELETE_USERADDRESS_FAIL = "DELETE_USERADDRESS_FAIL"
