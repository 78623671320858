import {
  GET_COMPLAINTS,
  GET_COMPLAINTS_SUCCESS,
  GET_COMPLAINTS_FAIL,
  GET_COMPLAINT_DETAILS,
  GET_COMPLAINT_DETAILS_SUCCESS,
  GET_COMPLAINT_DETAILS_FAIL,
  CREATE_COMPLAINT,
  CREATE_COMPLAINT_SUCCESS,
  CREATE_COMPLAINT_FAIL,
  UPDATE_COMPLAINT,
  UPDATE_COMPLAINT_SUCCESS,
  UPDATE_COMPLAINT_FAIL,
  DELETE_COMPLAINT,
  DELETE_COMPLAINT_SUCCESS,
  DELETE_COMPLAINT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  complaints: {},
  complaintDetails: {},
  error: {},
  loading: false,
}

const Complaint = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Complaint - This line cannot be edited or removed
    case GET_COMPLAINTS:
    case GET_COMPLAINT_DETAILS:
    case CREATE_COMPLAINT:
    case UPDATE_COMPLAINT:
    case DELETE_COMPLAINT:
      return {
        ...state,
        loading: true,
      }

    case DELETE_COMPLAINT_FAIL:
    case GET_COMPLAINTS_FAIL:
    case GET_COMPLAINT_DETAILS_FAIL:
    case UPDATE_COMPLAINT_FAIL:
    case CREATE_COMPLAINT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: action.payload,
        error: {},
        loading: false,
      }

    case GET_COMPLAINT_DETAILS_SUCCESS:
      return {
        ...state,
        complaintDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaints: {
          ...state.complaints,
          complaints: [...state.complaints.complaints, action.payload],
        },
        // complaintDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaints: {
          ...state.complaints,
          complaints: state.complaints.complaints.map(complaint =>
            complaint._id === action.payload._id
              ? { ...complaint, ...action.payload }
              : complaint
          ),
        },
        complaintDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaints: {
          ...state.complaints,
          complaints: state.complaints.complaints.filter(
            complaint => complaint._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Complaint
