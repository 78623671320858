import {
  GET_BIDS,
  GET_BIDS_SUCCESS,
  GET_BIDS_FAIL,
  GET_BID_DETAILS,
  GET_BID_DETAILS_SUCCESS,
  GET_BID_DETAILS_FAIL,
  CREATE_BID,
  CREATE_BID_SUCCESS,
  CREATE_BID_FAIL,
  UPDATE_BID,
  UPDATE_BID_SUCCESS,
  UPDATE_BID_FAIL,
  DELETE_BID,
  DELETE_BID_SUCCESS,
  DELETE_BID_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  bids: {},
  bidDetails: {},
  error: {},
  loading: false,
}

const Bid = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Bid - This line cannot be edited or removed
    case GET_BIDS:
    case GET_BID_DETAILS:
    case CREATE_BID:
    case UPDATE_BID:
    case DELETE_BID:
      return {
        ...state,
        loading: true,
      }

    case DELETE_BID_FAIL:
    case GET_BIDS_FAIL:
    case GET_BID_DETAILS_FAIL:
    case UPDATE_BID_FAIL:
    case CREATE_BID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_BIDS_SUCCESS:
      return {
        ...state,
        bids: action.payload,
        error: {},
        loading: false,
      }

    case GET_BID_DETAILS_SUCCESS:
      return {
        ...state,
        bidDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_BID_SUCCESS:
      return {
        ...state,
        bids: {
          ...state.bids,
          bids: [...state.bids.bids, action.payload],
        },
        // bidDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_BID_SUCCESS:
      return {
        ...state,
        bids: {
          ...state.bids,
          bids: state.bids.bids.map(bid =>
            bid._id === action.payload._id ? { ...bid, ...action.payload } : bid
          ),
        },
        bidDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_BID_SUCCESS:
      return {
        ...state,
        bids: {
          ...state.bids,
          bids: state.bids.bids.filter(bid => bid._id !== action.payload._id),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default Bid
