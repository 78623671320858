import {
  GET_USERADDRESSS,
  GET_USERADDRESSS_SUCCESS,
  GET_USERADDRESSS_FAIL,
  GET_USERADDRESS_DETAILS,
  GET_USERADDRESS_DETAILS_SUCCESS,
  GET_USERADDRESS_DETAILS_FAIL,
  CREATE_USERADDRESS,
  CREATE_USERADDRESS_SUCCESS,
  CREATE_USERADDRESS_FAIL,
  UPDATE_USERADDRESS,
  UPDATE_USERADDRESS_SUCCESS,
  UPDATE_USERADDRESS_FAIL,
  DELETE_USERADDRESS,
  DELETE_USERADDRESS_SUCCESS,
  DELETE_USERADDRESS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  useraddresss: {},
  useraddressDetails: {},
  error: {},
  loading: false,
}

const UserAddress = (state = INIT_STATE, action) => {
  switch (action.type) {
    // UserAddress - This line cannot be edited or removed
    case GET_USERADDRESSS:
    case GET_USERADDRESS_DETAILS:
    case CREATE_USERADDRESS:
    case UPDATE_USERADDRESS:
    case DELETE_USERADDRESS:
      return {
        ...state,
        loading: true,
      }

    case DELETE_USERADDRESS_FAIL:
    case GET_USERADDRESSS_FAIL:
    case GET_USERADDRESS_DETAILS_FAIL:
    case UPDATE_USERADDRESS_FAIL:
    case CREATE_USERADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_USERADDRESSS_SUCCESS:
      return {
        ...state,
        useraddresss: action.payload,
        error: {},
        loading: false,
      }

    case GET_USERADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        useraddressDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_USERADDRESS_SUCCESS:
      return {
        ...state,
        useraddresss: action.payload,
        useraddressDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_USERADDRESS_SUCCESS:
      return {
        ...state,
        useraddresss: {
          ...state.useraddresss,
          useraddresss: state.useraddresss.useraddresss.map(useraddress =>
            useraddress._id === action.payload._id
              ? { ...useraddress, ...action.payload }
              : useraddress
          ),
        },
        useraddressDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_USERADDRESS_SUCCESS:
      return {
        ...state,
        useraddresss: {
          ...state.useraddresss,
          useraddresss: state.useraddresss.useraddresss.filter(
            useraddress => useraddress._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default UserAddress
