export const LOGIN_USER_MOBILE = "LOGIN_USER_MOBILE"
export const LOGIN_USER_MOBILE_SUCCESS = "LOGIN_USER_MOBILE_SUCCESS"
export const LOGIN_USER_MOBILE_FAILD = "LOGIN_USER_MOBILE_FAILD"

export const REGISTER_USER_OTP = "REGISTER_USER_OTP"
export const REGISTER_USER_OTP_SUCCESS = "REGISTER_USER_OTP_SUCCESS"
export const REGISTER_USER_OTP_FAILD = "REGISTER_USER_OTP_FAILD"

export const LOGIN_USER_OTP = "LOGIN_USER_OTP"
export const LOGIN_USER_OTP_SUCCESS = "LOGIN_USER_OTP_SUCCESS"
export const LOGIN_USER_OTP_FAILD = "LOGIN_USER_OTP_FAILD"

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAILD = "GET_USER_FAILD"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAILD = "LOGOUT_USER_FAILD"

export const UPDATE_USER_INFO = "UPDATE_USER_INFO"
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS"
export const UPDATE_USER_INFO_FAILD = "UPDATE_USER_INFO_FAILD"
