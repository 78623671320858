import {
  GET_BIDS,
  GET_BIDS_SUCCESS,
  GET_BIDS_FAIL,
  GET_BID_DETAILS,
  GET_BID_DETAILS_SUCCESS,
  GET_BID_DETAILS_FAIL,
  CREATE_BID,
  CREATE_BID_SUCCESS,
  CREATE_BID_FAIL,
  UPDATE_BID,
  UPDATE_BID_SUCCESS,
  UPDATE_BID_FAIL,
  DELETE_BID_SUCCESS,
  DELETE_BID_FAIL,
  DELETE_BID,
} from "./actionTypes"

// Bid - This line cannot be edited or removed
export const getBids = productId => ({
  type: GET_BIDS,
  payload: { productId },
})

export const getBidsSuccess = bids => ({
  type: GET_BIDS_SUCCESS,
  payload: bids,
})

export const getBidsFail = error => ({
  type: GET_BIDS_FAIL,
  payload: error,
})

export const getBidDetails = bidId => ({
  type: GET_BID_DETAILS,
  payload: bidId,
})

export const getBidDetailsSuccess = bidDetails => ({
  type: GET_BID_DETAILS_SUCCESS,
  payload: bidDetails,
})

export const getBidDetailsFail = error => ({
  type: GET_BID_DETAILS_FAIL,
  payload: error,
})

export const createBid = (bid, dispatch) => ({
  type: CREATE_BID,
  payload: { bid, dispatch },
})

export const createBidSuccess = bid => ({
  type: CREATE_BID_SUCCESS,
  payload: bid,
})

export const createBidFail = error => ({
  type: CREATE_BID_FAIL,
  payload: error,
})

export const getBidDetail = bidId => ({
  type: GET_BID_DETAILS,
  payload: bidId,
})

export const updateBid = (bid, bidId, history) => ({
  type: UPDATE_BID,
  payload: { bid, bidId, history },
})

export const updateBidSuccess = bid => ({
  type: UPDATE_BID_SUCCESS,
  payload: bid,
})

export const updateBidFail = error => ({
  type: UPDATE_BID_FAIL,
  payload: error,
})

export const deleteBid = (bidId, history) => ({
  type: DELETE_BID,
  payload: { bidId, history },
})

export const deleteBidSuccess = bid => ({
  type: DELETE_BID_SUCCESS,
  payload: bid,
})

export const deleteBidFail = error => ({
  type: DELETE_BID_FAIL,
  payload: error,
})
