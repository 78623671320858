import { TOKEN_PREFIX } from "helpers/api_methods"
import React from "react"
import { AiOutlineShopping } from "react-icons/ai"
import { FaCommentDollar } from "react-icons/fa"
import {
  HiOutlineHome,
  HiOutlineShoppingCart,
  HiOutlineUserCircle,
  HiOutlineViewGrid,
} from "react-icons/hi"
import { RiFeedbackLine } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import { showLoginPopupAction } from "store/actions"

function MobileNav() {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const token = localStorage.getItem(TOKEN_PREFIX)

  const { cartItems, localCart, isLoginPopup, cartNoti, mobileNav } =
    useSelector(state => ({
      cartItems: state.Cart.cartItems,
      localCart: state.Cart.localCart,
      isLoginPopup: state.Layout.isLoginPopup,
      cartNoti: state.Layout.cartNoti,
      mobileNav: state.Layout.mobileNav,
    }))

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else if (localCart?.length >= 1) {
      return localCart?.filter(i => i?.quantity !== 0)
    } else {
      return []
    }
  }

  const handleAuth = () => {
    if (token) {
      history.push("/user-account")
    } else {
      dispatch(showLoginPopupAction())
    }
  }

  return (
    <>
      {location.pathname === "/checkout" ? (
        <></>
      ) : (
        <div
          className={
            "MobileNav " + `${mobileNav === false ? "hideToBottom" : ""}`
          }
          style={{
            boxShadow:
              isLoginPopup || cartNoti
                ? ""
                : "rgb(149 157 165 / 20%) 0px -5px 24px",
          }}
        >
          <Link to={"/"} className="text-gray mobile_nav_item">
            <HiOutlineHome />
            <span>Home</span>
          </Link>
          <Link
            to={{
              pathname: "/user-account",
              state: { index: 2 },
              // query: {
              //   prod: "all",
              // },
            }}
            className="text-gray mobile_nav_item"
          >
            <AiOutlineShopping />
            <span>Orders</span>
          </Link>
          <Link
            to={{
              pathname: "/user-account",
              state: { index: 6 },
              // query: {
              //   prod: "all",
              // },
            }}
            className="text-gray mobile_nav_item"
          >
            <FaCommentDollar />
            <span>Biddings</span>
          </Link>
          <Link
            to={{
              pathname: "/user-account",
              state: { index: 7 },
              // query: {
              //   prod: "all",
              // },
            }}
            className="text-gray mobile_nav_item position-relative"
          >
            <RiFeedbackLine />
            <span>Complaints</span>
            {/* {handleCartItem()?.length >= 1 && (
              <span
                className="pro-count text-white navIcon2 bg-darkGreen px-1"
                style={{ borderRadius: "50%" }}
              >
                {handleCartItem()?.length}
              </span>
            )} */}
          </Link>
        </div>
      )}
    </>
  )
}

export default MobileNav
