import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_COMPLAINTS,
  GET_COMPLAINT_DETAILS,
  CREATE_COMPLAINT,
  UPDATE_COMPLAINT,
  DELETE_COMPLAINT,
} from "./actionTypes"
import {
  getComplaintsFail,
  getComplaintsSuccess,
  getComplaintDetailsSuccess,
  getComplaintDetailsFail,
  createComplaintFail,
  createComplaintSuccess,
  updateComplaintSuccess,
  updateComplaintFail,
  deleteComplaintSuccess,
  deleteComplaintFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"
import { showToasterAction } from "store/actions"

// Complaint - This line cannot be edited or removed
function getComplaintsAPi({ page, limit, searchText }) {
  return get(
    `/complaint/user/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}`
  )
}

const getComplaintDetailsAPi = ComplaintId => {
  return get(`/complaint/admin/single/${ComplaintId}`)
}

const createComplaintApi = ({ complaint }) => {
  return post("/complaint/user/new", complaint)
}

const updateComplaintApi = ({ complaint, ComplaintId }) => {
  return ApiPut(`/complaint/admin/${ComplaintId}`, complaint)
}

const deleteComplaintApi = ComplaintId => {
  return del(`/complaint/admin/${ComplaintId}`)
}

function* fetchComplaints({ payload }) {
  try {
    const response = yield call(getComplaintsAPi, payload)
    yield put(getComplaintsSuccess(response))
  } catch (error) {
    yield put(getComplaintsFail(error))
  }
}

function* fetchComplaintDetails({ payload: ComplaintId }) {
  try {
    const response = yield call(getComplaintDetailsAPi, ComplaintId)
    yield put(getComplaintDetailsSuccess(response))
  } catch (error) {
    yield put(getComplaintDetailsFail(error))
  }
}

function* onCreateComplaint({ payload }) {
  try {
    const response = yield call(createComplaintApi, payload)
    if (!response?._id) {
      payload?.dispatch(
        showToasterAction({
          data: {
            title: "",
            message: response?.message,
          },
          type: "default",
        })
      )
      // Notification({
      //   type: "error",
      //   message: response?.message,
      //   title: "",
      // })
    } else if (response) {
      yield put(createComplaintSuccess(response))
      payload?.dispatch(
        showToasterAction({
          data: {
            title: "",
            message: "Submitted Successfully",
          },
          type: "success",
        })
      )
      // payload?.history?.push("/complaints")
      // doneNotification("Complaint Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createComplaintFail(error))
  }
}

function* onUpdateComplaint({ payload }) {
  try {
    const response = yield call(updateComplaintApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Complaint Updated Successfully!",
        title: "",
      })
      yield put(updateComplaintSuccess(response))
      if (payload.history) {
        payload.history.push("/complaints")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateComplaintFail(error.response.data))
  }
}

function* onDeleteComplaint({ ComplaintId, history }) {
  try {
    const response = yield call(deleteComplaintApi, ComplaintId)
    yield put(deleteComplaintSuccess(response))
    Notification({
      type: "success",
      message: "Complaint Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteComplaintFail(error?.response?.data))
  }
}

function* complaintSaga() {
  yield takeEvery(GET_COMPLAINTS, fetchComplaints)
  yield takeEvery(GET_COMPLAINT_DETAILS, fetchComplaintDetails)
  yield takeEvery(CREATE_COMPLAINT, onCreateComplaint)
  yield takeEvery(UPDATE_COMPLAINT, onUpdateComplaint)
  yield takeEvery(DELETE_COMPLAINT, onDeleteComplaint)
}

export default complaintSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
