module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "ed475f",
  companyName: "woodLog",
  shortName: "woodLog",
  siteMetadata: {
    websiteTitle: "woodLogs",
    description: "woodLogs",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.34:4005",
  apiEndpointProd: "https://api.woodlog.vingb.com/",
  frontEndUrl: "https://woodlog.vingb.com/",
  tokenPrefix: "woodLog_token",
}
