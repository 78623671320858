import {
  GET_PREORDERS,
  GET_PREORDERS_SUCCESS,
  GET_PREORDERS_FAIL,
  GET_PREORDER_DETAILS,
  GET_PREORDER_DETAILS_SUCCESS,
  GET_PREORDER_DETAILS_FAIL,
  CREATE_PREORDER,
  CREATE_PREORDER_SUCCESS,
  CREATE_PREORDER_FAIL,
  UPDATE_PREORDER,
  UPDATE_PREORDER_SUCCESS,
  UPDATE_PREORDER_FAIL,
  DELETE_PREORDER_SUCCESS,
  DELETE_PREORDER_FAIL,
  DELETE_PREORDER,
} from "./actionTypes"

// Preorder - This line cannot be edited or removed
export const getPreorders = (page, limit, searchText) => ({
  type: GET_PREORDERS,
  payload: { page, limit, searchText },
})

export const getPreordersSuccess = Preorders => ({
  type: GET_PREORDERS_SUCCESS,
  payload: Preorders,
})

export const getPreordersFail = error => ({
  type: GET_PREORDERS_FAIL,
  payload: error,
})

export const getPreorderDetails = PreorderId => ({
  type: GET_PREORDER_DETAILS,
  payload: PreorderId,
})

export const getPreorderDetailsSuccess = PreorderDetails => ({
  type: GET_PREORDER_DETAILS_SUCCESS,
  payload: PreorderDetails,
})

export const getPreorderDetailsFail = error => ({
  type: GET_PREORDER_DETAILS_FAIL,
  payload: error,
})

export const createPreorder = (preorder, history) => ({
  type: CREATE_PREORDER,
  payload: { preorder, history },
})

export const createPreorderSuccess = preorder => ({
  type: CREATE_PREORDER_SUCCESS,
  payload: preorder,
})

export const createPreorderFail = error => ({
  type: CREATE_PREORDER_FAIL,
  payload: error,
})

export const getPreorderDetail = PreorderId => ({
  type: GET_PREORDER_DETAILS,
  payload: PreorderId,
})

export const updatePreorder = (Preorder, PreorderId, history) => ({
  type: UPDATE_PREORDER,
  payload: { Preorder, PreorderId, history },
})

export const updatePreorderSuccess = Preorder => ({
  type: UPDATE_PREORDER_SUCCESS,
  payload: Preorder,
})

export const updatePreorderFail = error => ({
  type: UPDATE_PREORDER_FAIL,
  payload: error,
})

export const deletePreorder = (PreorderId, history) => ({
  type: DELETE_PREORDER,
  payload: { PreorderId, history },
})

export const deletePreorderSuccess = Preorder => ({
  type: DELETE_PREORDER_SUCCESS,
  payload: Preorder,
})

export const deletePreorderFail = error => ({
  type: DELETE_PREORDER_FAIL,
  payload: error,
})
