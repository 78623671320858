import {
  GET_COMPLAINTS,
  GET_COMPLAINTS_SUCCESS,
  GET_COMPLAINTS_FAIL,
  GET_COMPLAINT_DETAILS,
  GET_COMPLAINT_DETAILS_SUCCESS,
  GET_COMPLAINT_DETAILS_FAIL,
  CREATE_COMPLAINT,
  CREATE_COMPLAINT_SUCCESS,
  CREATE_COMPLAINT_FAIL,
  UPDATE_COMPLAINT,
  UPDATE_COMPLAINT_SUCCESS,
  UPDATE_COMPLAINT_FAIL,
  DELETE_COMPLAINT_SUCCESS,
  DELETE_COMPLAINT_FAIL,
  DELETE_COMPLAINT,
} from "./actionTypes"

// Complaint - This line cannot be edited or removed
export const getComplaints = (page, limit, searchText) => ({
  type: GET_COMPLAINTS,
  payload: { page, limit, searchText },
})

export const getComplaintsSuccess = Complaints => ({
  type: GET_COMPLAINTS_SUCCESS,
  payload: Complaints,
})

export const getComplaintsFail = error => ({
  type: GET_COMPLAINTS_FAIL,
  payload: error,
})

export const getComplaintDetails = ComplaintId => ({
  type: GET_COMPLAINT_DETAILS,
  payload: ComplaintId,
})

export const getComplaintDetailsSuccess = ComplaintDetails => ({
  type: GET_COMPLAINT_DETAILS_SUCCESS,
  payload: ComplaintDetails,
})

export const getComplaintDetailsFail = error => ({
  type: GET_COMPLAINT_DETAILS_FAIL,
  payload: error,
})

export const createComplaint = (complaint, dispatch) => ({
  type: CREATE_COMPLAINT,
  payload: { complaint, dispatch },
})

export const createComplaintSuccess = complaint => ({
  type: CREATE_COMPLAINT_SUCCESS,
  payload: complaint,
})

export const createComplaintFail = error => ({
  type: CREATE_COMPLAINT_FAIL,
  payload: error,
})

export const getComplaintDetail = ComplaintId => ({
  type: GET_COMPLAINT_DETAILS,
  payload: ComplaintId,
})

export const updateComplaint = (Complaint, ComplaintId, history) => ({
  type: UPDATE_COMPLAINT,
  payload: { Complaint, ComplaintId, history },
})

export const updateComplaintSuccess = Complaint => ({
  type: UPDATE_COMPLAINT_SUCCESS,
  payload: Complaint,
})

export const updateComplaintFail = error => ({
  type: UPDATE_COMPLAINT_FAIL,
  payload: error,
})

export const deleteComplaint = (ComplaintId, history) => ({
  type: DELETE_COMPLAINT,
  payload: { ComplaintId, history },
})

export const deleteComplaintSuccess = Complaint => ({
  type: DELETE_COMPLAINT_SUCCESS,
  payload: Complaint,
})

export const deleteComplaintFail = error => ({
  type: DELETE_COMPLAINT_FAIL,
  payload: error,
})
