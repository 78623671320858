import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERADDRESSS,
  GET_USERADDRESS_DETAILS,
  CREATE_USERADDRESS,
  UPDATE_USERADDRESS,
  DELETE_USERADDRESS,
} from "./actionTypes"
import {
  getUserAddresssFail,
  getUserAddresssSuccess,
  getUserAddressDetailsSuccess,
  getUserAddressDetailsFail,
  createUserAddressFail,
  createUserAddressSuccess,
  updateUserAddressSuccess,
  updateUserAddressFail,
  deleteUserAddressSuccess,
  deleteUserAddressFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"

// UserAddress - This line cannot be edited or removed
function getUserAddresssAPi({ page, sort, limit, searchText }) {
  return get(
    `/user/address?sort=${sort ? sort : 10}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}`
  )
}

const getUserAddressDetailsAPi = useraddressId => {
  return get(`/user/address/single/${useraddressId}`)
}

const createUserAddressApi = ({ useraddress }) => {
  return post("/user/address/new", useraddress)
}

const updateUserAddressApi = ({ useraddress, useraddressId }) => {
  return ApiPut(`/user/address/${useraddressId}`, useraddress)
}

const deleteUserAddressApi = useraddressId => {
  return del(`/user/address/${useraddressId}`)
}

function* fetchUserAddresss({ payload }) {
  try {
    const response = yield call(getUserAddresssAPi, payload)
    yield put(getUserAddresssSuccess(response))
  } catch (error) {
    yield put(getUserAddresssFail(error))
  }
}

function* fetchUserAddressDetails({ payload: useraddressId }) {
  try {
    const response = yield call(getUserAddressDetailsAPi, useraddressId)
    yield put(getUserAddressDetailsSuccess(response))
  } catch (error) {
    yield put(getUserAddressDetailsFail(error))
  }
}

function* onCreateUserAddress({ payload }) {
  try {
    const response = yield call(createUserAddressApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createUserAddressSuccess(response))
      payload?.history?.push("/useraddresss")
      doneNotification("UserAddress Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createUserAddressFail(error))
  }
}

function* onUpdateUserAddress({ payload }) {
  try {
    const response = yield call(updateUserAddressApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "UserAddress Updated Successfully!",
        title: "",
      })
      yield put(updateUserAddressSuccess(response))
      if (payload.history) {
        payload.history.push("/useraddresss")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateUserAddressFail(error.response.data))
  }
}

function* onDeleteUserAddress({ UserAddressId, history }) {
  try {
    const response = yield call(deleteUserAddressApi, UserAddressId)
    yield put(deleteUserAddressSuccess(response))
    Notification({
      type: "success",
      message: "UserAddress Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteUserAddressFail(error?.response?.data))
  }
}

function* useraddressSaga() {
  yield takeEvery(GET_USERADDRESSS, fetchUserAddresss)
  yield takeEvery(GET_USERADDRESS_DETAILS, fetchUserAddressDetails)
  yield takeEvery(CREATE_USERADDRESS, onCreateUserAddress)
  yield takeEvery(UPDATE_USERADDRESS, onUpdateUserAddress)
  yield takeEvery(DELETE_USERADDRESS, onDeleteUserAddress)
}

export default useraddressSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
