import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//contacts
import Cart from "./cart&order/reducer"

//product
import Products from "./product/reducer"

//layout groups
import LayoutGroup from "./group&collections/reducer"

//banner
import Banner from "./banner/reducer"

//classification
import Classification from "./classifications/reducer"

//settings
import SiteSettings from "./settings/reducer"

//pages
import LayoutPages from "./pages/reducer"

//blog
import Blogs from "./blogs/reducer"

//preorder
import Preorder from "./preorder/reducer"

import Complaint from "./complaint/reducer"

import UserAddress from "./useraddress/reducer"

import Bid from "./bid/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Cart,
  Products,
  LayoutGroup,
  Banner,
  Classification,
  SiteSettings,
  LayoutPages,
  Blogs,
  Preorder,
  Complaint,
  UserAddress,
  Bid,
})

export default rootReducer
